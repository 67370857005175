/*-----------------------------------------------------------------------------------

[Table of contents]

1. Font
2. Css Variable for colors
3. Common CSS
4. Preloader CSS
5. Header - Main Navigation ( section )
6. Hero Slider ( section )
7. Trusted Logo Slider ( section )
8. Features ( section )
9. About us ( section )
10. Modern UI ( section )
11. How it works ( section )
12. Testimonials ( section )
13. Pricing ( section )
14. Faq ( section )
15. Interface ( section )
16. Download app ( section )
17. Latest story ( section )
18. Newsletter ( section )
19. Footer ( section )
20. Animation CSS ( section )


-----------------------------------------------------------------------------------*/


/* --------Font--------------- */
/* poppins-300 - latin */


/* -----------Css-variable------ */
.swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
}

.swiper-pagination-bullet,
.slick-dots li button {
    width: 15px;
    height: 15px;
}

.slick-dots li button:before {
    font-size: 15px;
}

.swiper-pagination-bullet-active {
    background-color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #ccc;
    opacity: 1;
}

:root {
    --light-green: #f3feff;
    --green: linear-gradient(#7D9FA1, #4D6E72);
    --bg-green: #7D9FA1;
    --dark-green: #4D6E72;
    --body-text-green: #4D6E72;
    --text-white: #ffffff;
    --bg-white: #ffffff;
    --slider-dots-color: #D4D2DD;
    --light-bg: #add0d2;
}



/* ------Common-Css------------- */

html {
    scroll-behavior: smooth
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.7;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    background: var(--green);
    /* cursor: url("/public/images/injection.png"), auto; */
}

.circle-left {
    width: 99%;
    margin-top: -80px;
}

.circle-right {
    width: 99%;
    margin-top: -80px;
    float: right;
}

.page_wrapper {
    width: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--body-text-green);
}

a:hover {
    text-decoration: none;
    color: var(--body-text-green);
}

ul,
li {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

ol li {
    list-style: revert;
}

button:focus,
.btn.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

@media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

.section_title {
    text-align: center;
}

/* section heading h2 */
.section_title h2 {
    font-size: 40px;
    font-weight: 700;

}

.section_title h2 span {
    color: var(--green);
}

.row_am {
    padding: 70px 0;
}

/* purple button */
.puprple_btn {
    background-color: var(--green);
    color: var(--text-white);
    border-radius: 50px;
    padding: 10px 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
}

.puprple_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.puprple_btn:hover::before {
    width: 100%;
}

.puprple_btn:hover {
    color: var(--green);
}

/* white button */
.white_btn {
    padding: 10px 45px;
    border: 1px solid var(--green);
    color: var(--green);
    border-radius: 50px;
    background-color: var(--bg-white);
    font-weight: 700;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 500;
}

.white_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-green);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.white_btn:hover::before {
    width: 110%;
}

.white_btn:hover {
    color: var(--text-white);
}

.highlited_block .white_btn:hover {
    border-color: var(--bg-white);
}

/* slider controls */
.owl-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
}

.owl-carousel .owl-dots button {
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--slider-dots-color);
    border-radius: 15px;
    margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
    background-color: var(--green);
}

/* -------------Preloader-Css-Start-------------- */

/* Preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999999;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--bg-green);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--body-text-green);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--dark-green);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




/* -----------Header-Css-Start------------------- */
/* header wraper */
header {
    position: absolute;
    width: 100%;
    z-index: 99999;
    transition: .4s all;
}

.nav-link {
    color: #fff;
}

header.fix_style {
    position: fixed;
    top: 0;
    backdrop-filter: blur(5px);
    background-color: #ffffffb4;
    padding: 15px 0;
    transition: none;
    opacity: 0;
    pointer-events: none;
}

header.fixed {
    pointer-events: all;
    opacity: 1;
    transition: .4s all;
}

header.fixed .navbar {
    padding: 0;
}

/* navigation bar */
.navbar {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.navbar-expand-lg .navbar-nav {
    align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 5px 15px;
    font-weight: 500;
    font-size: 18px;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--green);
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    /* color: var(--text-white);
    background: var(--green); */
    background: #fff;
    color: var(--dark-green);
    font-size: 16px;
    padding: 9px 40px;
    border-radius: 25px;
    margin-left: 20px;
    position: relative;
}


.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before,
.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 42px;
    z-index: -1;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
    animation: pulse-blue-medium-sm 3.5s infinite
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    animation: pulse-blue-small-sm 3.5s infinite
}

.navbar-brand img {
    width: 280px;
    position: relative;
    top: 20px;
}

/* navigation bar dropdown */
.navbar-expand-lg .navbar-nav .has_dropdown {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px 10px 0 0;
    transition: .4s all;
}

/* .navbar-expand-lg .navbar-nav .has_dropdown:hover {
    background-color: var(--bg-white);
    box-shadow: 0px 4px 10px #c5c5c580;
} */

.navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    position: relative;
    right: 15px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
    position: absolute;
    top: 100%;
    background-color: var(--bg-white);
    border-radius: 0 10px 10px 10px;
    min-width: 210px;
    max-width: 230px;
    margin-top: -10px;
    transition: .4s all;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 4px 10px #c5c5c580;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
    margin-left: 0;
    padding: 10px 20px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
    font-size: 15px;
    position: relative;
    transition: .4s all;
    line-height: 35px;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid var(--green);
    border-radius: 10px;
    margin-right: 5px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: .4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover {
    padding-left: 15px;
    color: var(--green);
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover::before {
    opacity: 1;
    left: 0;
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover>a,
.navbar-expand-lg .navbar-nav .has_dropdown:hover>.drp_btn {
    color: var(--green);
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
    opacity: 1;
    pointer-events: all;
    margin-top: -1px;
}

header.sticky {
    position: fixed;
    background: #374f51;
}

.sticky .navbar-brand img {
    width: 180px;
    top: 0px;
}

.sticky .navbar {
    padding: 5px 0px;
}

/* navigation toggle menu */
.toggle-wrap {
    padding: 10px;
    position: relative;
    cursor: pointer;

    /*disable selection*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.toggle-bar {
    width: 25px;
    margin: 10px 0;
    position: relative;
    border-top: 4px solid var(--body-text-green);
    display: block;
}

.toggle-bar::before,
.toggle-bar::after {
    content: "";
    display: block;
    background: var(--body-text-green);
    height: 4px;
    width: 30px;
    position: absolute;
    top: -12px;
    right: 0px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform-origin: 13%;
    -webkit-transform-origin: 13%;
    transform-origin: 13%;
}

.white_header .toggle-bar,
.white_header .toggle-bar::before,
.white_header .toggle-bar::after {
    border-top: 4px solid var(--bg-white);
}

.toggle-bar::after {
    top: 4px;
}

.toggle-wrap.active .toggle-bar {
    border-top: 6px solid transparent;
}

.toggle-wrap.active .toggle-bar::before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.toggle-wrap.active .toggle-bar::after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


/* ---------Hero-Slider-Css-Start------------------ */
/* hero slider wraper */
.banner_section {
    margin-top: 200px;
    position: relative;
    padding-bottom: 70px;
}

.banner_section .container {
    position: relative;
}

/* wave backgound after banner */
.banner_section::after {
    /* content: ""; */
    display: block;
    background-image: url(../../../public/images/banner-shape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
    margin-top: -50px;
}

.banner_section .row {
    align-items: center;
}

/* hero slider text */
.banner_section .banner_text {
    margin-top: -50px;
    text-align: right;
}

/* hero slider heading h1 */
.banner_section .banner_text h1 {
    font-size: 55px;
    color: #fff;
    letter-spacing: -1.5px;
    font-weight: 700;
}

.banner_section .banner_text h1 span {
    color: #314749;
}

/* hero slider button */
.banner_section .app_btn {
    display: flex;
    align-items: center;
}

/* hero slider list */
.banner_section .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--green);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: 200px;
}

.banner_section .app_btn li:last-child {
    margin-left: 25px;
}

.banner_section .app_btn li a img {
    transition: .4s all;
}

.banner_section .app_btn li a .white_img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.banner_section .app_btn li a:hover {
    background-color: var(--green);
}

.banner_section .app_btn li a:hover .blue_img {
    opacity: 0;
}

.banner_section .app_btn li a:hover .white_img {
    opacity: 1;
}

/* hero slider users */
.banner_section .used_app {
    display: flex;
    align-items: center;
    margin-top: 55px;
}

.banner_section .used_app ul {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.banner_section .used_app ul li:not(:first-child) {
    margin-left: -15px;
}

.banner_section .used_app p {
    line-height: 40px;
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
    color: #344c4e;
}

/* hero slider images */
.banner_section .banner_slider {
    display: flex;
    position: relative;
    margin-left: 11%;
    width: 98%;
}

.banner_section .banner_slider .left_icon {
    position: absolute;
    left: 15px;
    bottom: 70px;
    z-index: 9999;
}

.banner_section .banner_slider .right_icon {
    position: absolute;
    right: 15px;
    top: 70px;
}

/* hero slider mobile frame */
.banner_section .banner_slider .slider_frame {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 315px;
}

/* .slider_img{
    margin-left: 126px;
} */

.banner_section .banner_slider #frmae_slider::before {
    /* content: ""; */
    position: absolute;
    left: 5px;
    top: 5px;
    background-color: #fff;
    width: calc(100% - 10px);
    height: 92%;
}

.banner_section .banner_slider::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 475px;
    height: 475px;
    border-radius: 100%;
    background-color: var(--green);
    z-index: -5;
}

.banner_section .banner_slider #frmae_slider {
    width: 305px;
    margin: 0 auto;
    position: relative;
}

.banner_slider #frmae_slider .swiper {
    border-radius: 40px;
    overflow: hidden;
}

.banner_section .banner_slider #frmae_slider .swiper-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: none;
}

/* hero slider control dots */
.banner_section .owl-dots {
    margin-top: 40px;
}

.owl-carousel .owl-item img {
    max-width: 100%;
    width: auto;
}




/* ------------Trusted-Section-Css-Start----------- */

/* trusted logos wraper */
#company_slider {
    margin-top: 4em;
}

.trusted_section .item {
    text-align: center;
}

.trusted_section {
    margin-top: 40px;
}

.trusted_section .company_logos {
    padding-top: 20px;
}

.trusted_section .company_logos img {
    filter: grayscale(1);
    margin: 0 auto;
    transition: .4s all;
    width: 100px;
    margin-bottom: 15px;
}

.trusted_section .company_logos img:hover {
    filter: grayscale(0);
}



/* ----------Feature-Detail-Section-start------ */

/* features section wraper */
.features_section {
    padding-top: 2em;
}

.features_section .feature_detail {
    background-color: var(--dark-green);
    border-radius: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 85px;
    padding-top: 60px;
    padding-bottom: 20px;
    box-shadow: 0px 4px 30px #576769;
}

/* features section image */
.features_section .feature_detail .feature_img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
}

.features_section .feature_detail .feature_img img {
    max-width: 100%;
}

/* features section box */

.features_section .feature_detail .feature_box {
    max-width: 410px;
}

.features_section .feature_detail .feature_box .data_block {
    margin-bottom: 50px;
}

.features_section .feature_detail .feature_box .data_block h4 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
}

.features_section .feature_detail .feature_box .data_block p {
    color: #ffffff;
}

.features_section .feature_detail .left_data {
    text-align: right;
    padding-left: 130px;
}

.features_section .feature_detail .right_data {
    padding-right: 130px;
}

.features_section .feature_detail .left_data .data_block .icon {
    margin-right: -15px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.features_section .feature_detail .left_data .data_block .icon img {
    margin-left: 10px;
}

.features_section .feature_detail .right_data .data_block .icon {
    margin-left: -15px;
    display: flex;
    align-items: center;
}

.features_section .feature_detail .right_data .data_block .icon img {
    margin-right: 10px;
}

.features_section .container, .about_app_section .container{
    max-width: 1370px;
}



/* -----------------About-App-Section-Css-Start------------------ */

/* about us section wraper */
.about_app_section .about_img {
    display: flex;
    align-items: center;
    position: relative;
}

/* about us section images*/
.about_app_section .about_img img {
    max-width: 100%;
}


.about_app_section .about_img .screen_img {
    margin-left: -40px;
    margin-top: 110px;
}

.about_app_section .about_text .section_title {
    text-align: left;
}

.about_app_section .about_text .section_title h2 {
    margin-bottom: 15px;
}

/* about us section  statastics nomber */
.about_app_section .about_text .app_statstic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 40px;
}

.about_app_section .about_text .app_statstic li {
    width: 32%;
    background-color: var(--bg-white);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 15px 10px;
    padding-left: 15px;
    box-shadow: 0px 4px 10px #576769;
}

.about_app_section .about_text .app_statstic li .icon {
    margin-right: 9px;
}

.about_app_section .about_text .app_statstic li p {
    margin-bottom: 0;
    line-height: 1;
    color: var(--dark-green);
}

.about_app_section .about_text .app_statstic li p:first-child {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 3px;
}


/* -------------Modern-Ui-Section-Css-Start---------------- */
.modern_ui_section img{
    width: 200px;
}
/* modern ui section wraper */
.modern_ui_section .row {
    align-items: center;
}

.modern_ui_section .design_block {
    margin-top: 45px;
}

/* modern ui text */
.modern_ui_section .section_title {
    text-align: left;
}

.modern_ui_section .ui_text {
    /* padding-right: 75px; */
    background-color: var(--dark-green);
    padding: 70px 50px;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #7fa2a6;
    position: relative;
    overflow: hidden;
    text-align: center;
}
.modern_ui_section .ui_text  .section_title{
    text-align: center;
}

/* modern ui list */
.modern_ui_section .design_block li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 25px;
}

.modern_ui_section .design_block li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    /* background-image: url(../images/right_icon.png); */
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.modern_ui_section .design_block li h4 {
    font-size: 20px;
    color: var(--dark-green);
    font-weight: 600;
    margin-bottom: 8px;
}

.modern_ui_section .design_block li p {
    margin-bottom: 0;
}

/* modern ui images */
.modern_ui_section .ui_images {
    display: flex;
    position: relative;
}

.modern_ui_section .ui_images::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
    height: 570px;
    border-radius: 100%;
    background-color: var(--bg-white);
    z-index: -1;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
    margin-left: -140px;
    margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
    margin-left: -90px;
    margin-top: -20px;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
    position: relative;
    top: 15px;
    z-index: 99;
    margin-left: -15px;
}


/* -------------How_It_Works-Section-Css-Start------------------ */

/* how it works wraper */
.how_it_works .container {
    max-width: 1370px;
}

.how_it_works .how_it_inner {
    background-color: var(--dark-green);
    padding: 70px 50px;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #7fa2a6;
    position: relative;
    overflow: hidden;
}

/* how it works list */
.how_it_works .step_block ul {
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px;
}

.how_it_works .step_block ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
}

.how_it_works .step_block ul li::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% + 100px);
    background-color: var(--light-bg);
}

.how_it_works .step_block ul li:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: var(--light-bg);
    border-radius: 15px;
}

.how_it_works .step_block ul li:first-child::before {
    top: 0;
}

.how_it_works .step_block ul li:last-child::before {
    height: 50%;
    top: 0;
}

.how_it_works .step_block ul li .step_text,
.how_it_works .step_block ul li .step_img {
    width: 360px;
    text-align: right;
}

/* how it works image */
.how_it_works .step_block ul li .step_img img {
    max-width: 100%;
}

/* how it works heading h4 */
.how_it_works .step_block ul li .step_text h4 {
    font-size: 30px;
    font-weight: 600;
}

.how_it_works .step_block ul li .step_text .app_icon {
    margin-bottom: 10px;
}

.how_it_works .step_block ul li .step_text .app_icon a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--light-bg);
    color: var(--text-white);
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    transition: .4s all;
}

.how_it_works .step_block ul li .step_text .app_icon a:hover {
    background-color: var(--green);
}

.how_it_works .step_block ul li .step_text span {
    font-weight: 600;
}

.how_it_works .step_block ul li .step_text span a {
    color: var(--green);
    text-decoration: underline;
}

.how_it_works .step_block ul li:nth-child(2),
.how_it_works .step_block ul li:nth-child(4) {
    flex-direction: row-reverse;
}

.how_it_works .step_block ul li:nth-child(2) .step_text,
.how_it_works .step_block ul li:nth-child(2) .step_img,
.how_it_works .step_block ul li:nth-child(4) .step_text,
.how_it_works .step_block ul li:nth-child(4) .step_img {
    text-align: left;
}

/* how it works numbers */
.how_it_works .step_block .how-it-works .step_number {
    background-image: url('/public/images/icon_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

/* how it works numbers heading h3 */
.how_it_works .step_block ul li .step_number h3 {
    font-size: 30px;
    font-weight: 600;
}

/* how it works video  */
.how_it_works .yt_video {
    max-width: 1170px;
    margin: 0 auto;
    margin-top: -200px;
    position: relative;
    overflow: hidden;
}

/* how it works video animation line  */
.how_it_works .yt_video .anim_line {
    z-index: 999;
}

.how_it_works .yt_video .thumbnil {
    position: relative;
}

.how_it_works .yt_video .thumbnil img {
    max-width: 100%;
}

.how_it_works .yt_video .thumbnil a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--text-white);
    font-weight: 600;
    z-index: 999;
    cursor: pointer;
}

.how_it_works .yt_video .thumbnil a span {
    display: block;
    font-weight: 700;
    font-size: 30px;
}

.how_it_works .yt_video .thumbnil a .play_btn {
    background-color: rgba(255, 255, 255, 0.1);
    width: 96px;
    height: 96px;
    border-radius: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 96px;
    position: relative;
    display: block;
    margin-bottom: 40px;
}

.how_it_works .yt_video .thumbnil a .play_btn img {
    width: 50px;
    position: relative;
    z-index: 999;
}

.step_img img {
    width: 150px;
}

.flex-sec_left img {
    margin-right: 20px;
}

.flex-sec_left {
    display: flex;
    align-items: center;
}

.flex-sec_right img {
    margin-left: 20px;
}

.flex-sec_right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.top_circle_frame {
    position: absolute;
    top: -50px;
    width: 400px;
    left: -50px;
    opacity: 0.4;
}

.bottom_circle_frame {
    position: absolute;
    bottom: -50px;
    width: 400px;
    left: -50px;
    opacity: 0.4;
}

/* how it works video model   */
.modal {
    z-index: 999999;
}

.modal-backdrop.show {
    z-index: 99999;
    opacity: .7;
}

.youtube-video .modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    padding: 0 15px;
    height: 100%;
    max-width: 1240px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#video-container {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

iframe#youtubevideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.youtube-video .modal-footer {
    border: none;
    text-align: center;
    display: block;
    padding: 0;
}

.youtube-video .modal-content {
    background: none !important;
    border: none;
}

#close-video {
    color: #fff;
    font-size: 30px;
}

.how-it-works.row .col-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    position: relative;
}

.timeline .top-right {
    left: 50%;
    top: -50%;
}

.timeline .corner {
    border: 4px solid #7D9FA1;
    width: 100%;
    position: relative;
    border-radius: 15px;
}

.timeline div {
    padding: 0;
    height: 40px;
}

.timeline hr {
    border-top: 4px solid #7D9FA1;
    margin: 0;
    top: 16px;
    position: relative;
    opacity: 1;
}

.timeline .left-bottom {
    left: -50%;
    top: calc(50% - 4px);
}

.timeline .corner {
    border: 4px solid #7D9FA1;
    width: 100%;
    position: relative;
    border-radius: 15px;
}

.timeline .col-2 {
    display: flex;
    overflow: hidden;
}

.how-it-works.row .col-2.bottom::after {
    height: 50%;
    left: 50%;
    top: 50%;
}

.how-it-works.row .col-2::after {
    content: "";
    position: absolute;
    border-left: 4px solid #7D9FA1;
    z-index: 1;
}

.how-it-works.row .col-2.full::after {
    height: 100%;
    left: calc(50% - 4px);
}

.timeline .top-left {
    left: -50%;
    top: -50%;
}

.timeline .right-bottom {
    left: 50%;
    top: calc(50% - 4px);
}

.how-it-works.row .col-2.top::after {
    height: 50%;
    left: calc(50% - 4px);
    top: 0;
}

.step_block {
    width: 90%;
    margin: 0px auto;
}

.how-it-works.row .col-2.full.left::after {
    left: 50%;
}
.incubated_by {
    display: flex;
    align-items: center;
}
.incubated_by img {
    width: 280px;
    margin-left: 20px;
}
.how_it_inner .section_title{
text-align: left;
}
.how_it_inner img{
    max-width: 100%;
}
/* ------------Testimonial-Slider-Css-Start------------- */
/* testimonials wraper  */
.testimonial_section {
    padding-bottom: 9em;
}

#testimonial_slider {
    max-width: 550px;
    margin: 0 auto;
}

.testimonial_section .testimonial_block {
    /* background-image: url(../images/testimonial_bg.png); */
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 65px;
}

.testimonial_section .testimonial_block .testimonial_slide_box {
    text-align: center;
    width: 430px;
    padding: 10px;
    margin: 0 auto;
}

/* testimonials rating  */
.testimonial_section .testimonial_block .rating span {
    color: #FC9400;
    font-size: 18px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .review {
    margin-top: 10px;
    margin-bottom: 30px;
}

/* testimonials image  */
.testimonial_section .testimonial_block .testimonial_slide_box .testimonial_img img {
    margin: 0 auto;
}

/* testimonials heading h3 */
.testimonial_section .testimonial_block .testimonial_slide_box h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 10px;
}

.testimonial_section .testimonial_block .testimonial_slide_box .designation {
    font-size: 15px;
}

/* testimonials total review */
.testimonial_section .total_review {
    text-align: center;
    margin-top: 60px;
}

.testimonial_section .total_review .rating {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* testimonials paragraph */
.testimonial_section .total_review .rating p {
    margin-bottom: 0;
    font-weight: 600;
    margin-left: 5px;
    color: #fff;
}

/* testimonials heading */
.testimonial_section .total_review h3 {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 0;
    color: var(--dark-green);
}

.testimonial_section .total_review a {
    color: var(--green);
    font-weight: 700;
}

.testimonial_section .testimonial_block .avtar_faces {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 100%;
}

.testimonial_section .testimonial_block .avtar_faces img {
    max-width: 100%;
}


/* -------------------Pricing-Section---------------------- */

/* pricing wraper  */
.pricing_section .toggle_block {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* pricing toggle button */
.pricing_section .toggle_block span {
    color: var(--dark-green);
    font-weight: 600;
    display: block;
    margin: 0 5px;
}

.tog_btn.month_active {
    left: 35px !important;
}

.pricing_section .toggle_block span.deactive {
    color: var(--body-text-green);
}

.pricing_section .toggle_block .offer {
    background-color: var(--bg-white);
    border-radius: 5px;
    padding: 2px 10px;
    font-weight: 400;
    font-size: 13px;
    color: var(--green);
}

.pricing_section .toggle_block .tog_block {
    width: 70px;
    height: 35px;
    background-color: var(--bg-white);
    border-radius: 18px;
    margin: 0 10px;
    position: relative;
    cursor: pointer;
}

.pricing_section .toggle_block .tog_block .tog_btn {
    height: 23px;
    width: 23px;
    border-radius: 25px;
    display: block;
    background-color: var(--green);
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    transition: .4s all;
}

.pricing_section .toggle_block .month.active,
.pricing_section .toggle_block .years.active {
    color: var(--green);
}

/* pricing pannel */
.pricing_section {
    background: #799b9d;
}
.pricing_section .pricing_pannel {
    margin-top: 50px;
    display: none;
}

.pricing_section .pricing_pannel.active {
    display: block;
}

.pricing_section .pricing_pannel .pricing_block {
    text-align: center;
    background-color: var(--bg-white);
    min-height: 500px;
    border-radius: 12px;
    padding-top: 60px;
    margin-bottom: 40px;
    box-shadow: 0px 4px 30px #576769;
    color: #314749;
}

.pricing_section .pricing_pannel .pricing_block.highlited_block {
    background-color: var(--green);
}

.pricing_section .pricing_pannel .pricing_block.highlited_block p,
.pricing_section .pricing_pannel .pricing_block.highlited_block h3,
.pricing_section .pricing_pannel .pricing_block.highlited_block span,
.pricing_section .pricing_pannel .pricing_block.highlited_block .pkg_name span {
    color: var(--text-white);
}

.pricing_section .pricing_pannel .pricing_block .icon {
    margin-bottom: 35px;
}

/* pricing box image */
.pricing_section .pricing_pannel .pricing_block .icon img {
    width: 120px;
}

/* pricing box heading h3 */
.pricing_section .pricing_pannel .pricing_block .pkg_name h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.pricing_section .pricing_pannel .pricing_block .pkg_name span {
    color: var(--body-text-green);
    font-size: 15px;
}

.pricing_section .pricing_pannel .pricing_block .price {
    font-size: 48px;
    color: var(--green);
    margin: 25px 0;
    display: block;
    font-weight: 600;
}

/* pricing box list */
.pricing_section .pricing_pannel .pricing_block .benifits {
    margin-bottom: 40px;
}

.pricing_section .pricing_pannel .pricing_block .benifits li p {
    margin-bottom: 5px;
}

.pricing_section .contact_text {
    text-align: center;
    margin-bottom: 0;
}

.pricing_section .contact_text a {
    color: var(--green);
    text-decoration: underline;
}
.pricing_section .pricing_pannel .pricing_block .benifits li p {
    font-size: 22px;
}
.pricing_section .pricing_pannel .pricing_block .benifits li p span{
    font-size: 35px;
    font-weight: 500;
}
/* -------------dashboard section ----------------- */
.dashboard{
    text-align: left;
}
.dashboard img{
    max-width: 100%;
}
.dashboard .frame_img img{
    border-radius:20px;
    overflow: hidden;
}
.dash_text .section_title{
    text-align: left;
}
/* -------------FAQ-Section-Css-Start----------------- */

/* faq wraper */
.faq_section .faq_panel {
    margin-top: 40px;
}

/* faq box */
.faq_section .faq_panel .card {
    border: none;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #576769;
    padding: 16px 0;
}

.faq_section .faq_panel .card:last-child {
    margin-bottom: 0;
}

.faq_section .faq_panel .card-header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
}

.faq_section .faq_panel .card-header .btn {
    padding: 0;
    color: var(--dark-green);
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    text-align: left;
}

.faq_section .faq_panel .card-header .btn.active {
    color: var(--green);
}

.faq_panel .accordion button,
.faq_panel .accordion button:hover,
.faq_panel .accordion button:focus {
    text-decoration: none;
}

.faq_section .faq_panel .card-header .icon_faq {
    position: absolute;
    right: 20px;
    color: #839BC0;
}

/* faq heading h2 */
.faq_section .faq_panel .card-header h2 {
    line-height: 1;
}

/* faq paragraph */
.faq_section .faq_panel .card-body {
    padding-bottom: 0;
}


/* -----------Interface_Section-Css-Start----------------- */

/* interface wraper */
.interface_section .screen_slider {
    margin-top: 35px;
    min-height: 720px;
}

/* interface images */
.interface_section .slick-slide .screen_frame_img img {
    transform: scale(.9);
    border: 2px solid #000;
    border-radius: 20px;
    transition: 1s all;
    margin: 0 auto;
    width: 100%;
}

.interface_section .slick-slide.slick-current .screen_frame_img img {
    transform: scale(1);
    border: 3px solid #000;

}


/* -----------Download_App_Section-Start------------------ */

/* download app wraper */
.free_app_section {
    padding-top: 150px;
    position: relative;
}

.free_app_section .container {
    max-width: 1370px;
}

.free_app_section .container .free_app_inner {
    background-color: var(--bg-green);
    border-radius: 30px;
    padding: 20px 100px;
    padding-bottom: 50px;
    position: relative;
    z-index: 9;
}

/* download app dark background */
.free_app_section .container .free_app_inner .dark_bg {
    overflow: hidden;
}

.free_app_section .container .free_app_inner .dark_bg span {
    z-index: 9999;
}

.free_app_section .container .free_app_inner .row {
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .section_title {
    text-align: left;
}

/* download app heading h2 */
.free_app_section .container .free_app_inner .free_text .section_title h2 {
    margin-bottom: 20px;
}

.free_app_section .container .free_app_inner .free_text .section_title h2,
.free_app_section .container .free_app_inner .free_text .section_title p {
    color: var(--text-white);
}

.free_app_section .container .free_app_inner .free_text .app_btn {
    display: flex;
    align-items: center;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a {
    display: block;
    padding: 12px 30px;
    background-color: var(--bg-white);
    border: 2px solid var(--green);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: 200px;
}

.free_app_section .container .free_app_inner .free_text .app_btn li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
}

.free_app_section .container .free_app_inner .free_text .app_btn li:last-child {
    margin-left: 25px;
}

.free_app_section .container .free_app_inner .free_img {
    display: flex;
    align-items: center;
    margin-top: -120px;
}

.free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -65px;
}

.purple_backdrop {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(50, 35, 111, 0.95);
    width: 100%;
    height: 100%;
    z-index: 99999;
    opacity: 0;
    transition: .4s all;
    pointer-events: none;
}

/* ---------------Latest_Story-Css-Start------------- */

/* latest story wraper */
.latest_story {
    margin-bottom: 80px;
}

/* latest story box */
.latest_story .story_box {
    background-color: var(--bg-white);
    text-align: left;
    border-radius: 10px;
    box-shadow: 0px 4px 30px #576769;
}

/* latest story image */
.latest_story .story_box .story_img {
    position: relative;
}

.latest_story .story_box .story_img img {
    max-width: 100%;
    border-radius: 9px;
    width: 100%;
}

.latest_story .story_box .story_img span {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 14px;
    color: var(--text-white);
}

/* latest story pargraph */
.latest_story .story_box .story_text {
    padding: 20px 30px;
    color: var(--dark-green);


}

/* latest story heading h3 */
.latest_story .story_box .story_text h3 {
    color: var(--dark-green);
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
}

/* latest story link text */
.latest_story .story_box .story_text a {
    color: var(--green);
    margin-top: 25px;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.latest_story .story_box .story_text a:hover {
    text-decoration: underline;
}



/* -------------Newsletter-Section-Css-Start------------- */

/* newsletter  wraper */
.newsletter_box {
    background-color: var(--bg-green);
    padding: 50px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #0c0c0c21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -95px;
    position: relative;
    z-index: 99;
}

.newsletter_box .section_title {
    width: 45%;
}

.newsletter_box form {
    width: 60%;
}

/* newsletter heading h2 */
.newsletter_box .section_title h2 {
    margin-bottom: 5px;
    letter-spacing: -1px;
}

.newsletter_box .section_title h2,
.newsletter_box .section_title p {
    color: var(--text-white);
    text-align: left;
}

/* newsletter paragraph */
.newsletter_box .section_title p {
    margin-bottom: 0;
}

/* newsletter input  */
.newsletter_box form {
    display: flex;
}

.newsletter_box form .form-group {
    margin-bottom: 0;
}

.newsletter_box form .form-group .form-control {
    width: 430px;
    height: 55px;
    border-radius: 6px;
    color: var(--dark-green);
}

.newsletter_box form .form-group .form-control::placeholder {
    color: var(--dark-green);
}

/* newsletter button */
.newsletter_box form .form-group .btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: #fff;
    background-color: #4d6e72;
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.newsletter_box form .form-group .btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-green);
    border-radius: 0;
    transition: .6s all;
    z-index: -1;
}

.newsletter_box form .form-group .btn:hover::before {
    width: 100%;
}

.newsletter_box form .form-group .btn:hover {
    color: var(--text-white);
}


/* ------Footer-Css-Start-------------- */
/* footer wraper */
footer {
    position: relative;
}

footer .top_footer {
    background-color: #4d6e72;
    padding: 180px 0 60px 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

footer .top_footer.has_bg {
    /* background-image: url(../images/footer_bg.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* footer logo */
footer .top_footer .logo {
    margin-bottom: 40px;
}

footer .top_footer .logo img {
    width: 250px;
}

/* footer .top_footer .logo img {
    width: 150px;
} */

footer .top_footer .abt_side li {
    padding: 0 0 10px 0;
}

/* footer social media icon */
footer .top_footer .social_media {
    display: flex;
    margin-top: 20px;
}

/* footer link list */
footer .top_footer .social_media li a {
    display: block;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    margin-right: 10px;
    transition: .4s all;
}

footer .top_footer .social_media li a:hover {
    background-color: var(--bg-white);
    color: var(--green);
}

footer .top_footer .social_media li a:hover svg path {
    fill: #4d6e72;
}

footer .top_footer .try_out {
    margin-left: -20px;
}

footer .app_btn li a {
    display: block;
    padding: 12px 10px;
    background-color: var(--bg-white);
    border: 2px solid var(--green);
    position: relative;
    border-radius: 12px;
    transition: .4s all;
    width: 175px;
    text-align: center;
}

footer .app_btn li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
}

footer .app_btn li:last-child {
    margin-top: 20px;
}

footer .bottom_footer {
    background-color: var(--bg-green);
}

/* footer heading and text colors variable */
footer h2,
footer h3,
footer p,
footer a {
    color: var(--text-white);
}

footer a:hover {
    color: var(--text-white);
}

/* footer heading h3 */
footer h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
    padding-top: 10px;
}

footer .links ul li a {
    display: block;
    margin-bottom: 10px;
}

/* footer last */
footer .bottom_footer {
    padding: 20px 0;
}

footer .bottom_footer p {
    margin-bottom: 0;
    font-size: 15px;
}

footer .bottom_footer .developer_text {
    text-align: right;
}

footer .bottom_footer .developer_text a {
    text-decoration: underline;
}

/* footer go top button */
.go_top {
    position: fixed;
    right: 30px;
    bottom: 75px;
    cursor: pointer;
    transition: .4s all;
    opacity: 0;
}

.go_top:hover {
    bottom: 80px;
}

#contact-model .modal-content {
    background: #4D6E72;
}

.modal-title {
    width: 100%;
}

.model-close {
    position: absolute;
    background: #7b9d9f;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 7px;
    right: 0px;
    border-radius: 50%;
    top: -20px;
    right: -20px;
    z-index: 3;
}

input.form_submit_btn {
    height: 55px;
    width: 170px;
    text-transform: uppercase;
    color: #fff;
    background: #7b9d9f;
    border-radius: 6px;
    margin-left: 10px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 0px;
    margin: 0px;
}

/* -----------Animation-Css-Start-------------- */

/* animation line wraper */
.anim_line {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.anim_line span {
    position: absolute;
    z-index: 99999;
    top: -275px;
    animation: star_down_one 6s infinite linear;
    opacity: 0;
}

.anim_line.dark_bg {
    max-width: 1170px;
}

.anim_line.dark_bg span {
    transform: rotate(180deg);
}

.anim_line span:first-child {
    left: -17%;
    animation-delay: 3s;
}

.anim_line span:nth-child(2) {
    left: 0%;
    animation-delay: 5s;
}

.anim_line span:nth-child(3) {
    left: 17%;
    animation-delay: 1s;
}

.anim_line span:nth-child(4) {
    left: 34%;
    animation-delay: 4s;
}

.anim_line span:nth-child(5) {
    left: 51%;
    animation-delay: 7s;
}

.anim_line span:nth-child(6) {
    left: 68%;
}

.anim_line span:nth-child(7) {
    left: 85%;
    animation-delay: 3s;
}

.anim_line span:nth-child(8) {
    left: 99%;
    animation-delay: 2s;
}

.anim_line span:nth-child(9) {
    left: 117%;
    animation-delay: 5s;
}

/* footer .top_footer .anim_line span:first-child {
    left: 5%;
}

footer .top_footer .anim_line span:nth-child(2) {
    left: 13%;
} */

@keyframes star_down_one {
    0% {
        opacity: 0;
        top: -250px;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        top: 100%;
        opacity: 0;
    }
}




/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
    animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
    position: relative;
    animation: moving_position_animatin 6s infinite linear;
    z-index: -1;
}

.about_app_section .about_img .screen_img img {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
    animation-delay: 3s;
}

@keyframes moving_object {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes moving_position_animatin {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}


/* ------------Waves-Animation---------------- */
.waves-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

.waves {
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 100%;
    z-index: -1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
}

.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    position: absolute;
    top: 0;
}

.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    position: absolute;
    top: 0;
}

@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }

    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}



/*------Header Btn Animation------*/
@keyframes pulse-blue-small-sm {
    0% {
        box-shadow: 0 0 0 0 rgba(77, 110, 114, 0.7);
    }

    100%,
    30% {
        box-shadow: 0 0 0 12px transparent
    }
}

@keyframes pulse-blue-medium-sm {
    0% {
        box-shadow: 0 0 0 0 rgba(77, 110, 114, 0.4);
    }

    100%,
    30% {
        box-shadow: 0 0 0 20px transparent
    }
}


/*------WhiteHeader Btn Animation------*/
@keyframes pulse-white-small-sm {
    0% {
        box-shadow: 0 0 0 0 rgba(253, 253, 253, 0.7);
    }

    100%,
    30% {
        box-shadow: 0 0 0 12px transparent
    }
}

@keyframes pulse-white-medium-sm {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }

    100%,
    30% {
        box-shadow: 0 0 0 20px transparent
    }
}


/* Inner Page banner shape animation */
.bred_crumb .banner_shape1,
.banner_shape2,
.banner_shape3 {
    position: absolute;
}

.bred_crumb .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.bred_crumb .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.bred_crumb .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}


/* CTA section Shape animation */

.query_section .banner_shape1,
.banner_shape2,
.banner_shape3 {
    position: absolute;
}

.query_section .banner_shape1 {
    top: 200px;
    left: 15%;
    animation: mymove 10s infinite;
}

.query_section .banner_shape2 {
    top: 100px;
    right: 10%;
    animation: mymove 5s infinite;
}

.query_section .banner_shape3 {
    top: 350px;
    right: 20%;
    animation: mymove 3s infinite;
}


@keyframes mymove {
    50% {
        transform: rotate(180deg);
    }
}




/* ----------Other-Page-Css-Start---------- */

/* White Header Css Satart */

.white_header .navbar-expand-lg .navbar-nav .nav-link,
.white_header .navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    color: var(--text-white);
}

.white_header .navbar-expand-lg .navbar-nav .has_dropdown:hover .drp_btn {
    color: var(--green);
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    background-color: var(--bg-white);
    color: var(--green);
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
    animation: pulse-white-medium-sm 3.5s infinite
}

.white_header .navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    animation: pulse-white-small-sm 3.5s infinite
}

header.fix_style.white_header {
    background-color: #664bece0;
}


/* About Us Page Css Start */

/* Bredcrumb Css Start */
.bred_crumb {
    /* background-image: url(../images/bread_crumb_bg.png); */
    /*background: var(--green);*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 120px;
}

.bred_crumb::after {
    content: "";
    background-image: url(../../../public/images/inner_page_banner_overlay.svg);
    position: absolute;
    bottom: -1px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
    background-position: center;
}

.bred_crumb .bred_text {
    text-align: center;
    z-index: 1000;
    position: relative;
}

.bred_crumb .bred_text h1 {
    color: var(--text-white);
    font-size: 55px;
    font-weight: 700;
}

.bred_crumb .bred_text h1+p {
    color: var(--text-white);
    margin-top: -5px;
}

.bred_crumb .bred_text ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bred_crumb .bred_text ul li {
    margin: 0 5px;
}

.bred_crumb .bred_text ul li a,
.bred_crumb .bred_text ul li span {
    color: var(--text-white);
    font-size: 14px;
    transition: .4s all;
}

.bred_crumb .bred_text ul li a:hover {
    text-decoration: underline;
}

.bred_crumb .bred_text .search_bar {
    margin-top: 25px;
    position: relative;
    z-index: 1000;
}

.bred_crumb .bred_text .search_bar form .form-group {
    position: relative;
    max-width: 600px;
    height: 60px;
    margin: 0 auto;
}

.bred_crumb .bred_text .search_bar form .form-group .form-control {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid var(--bg-green);
    font-size: 16px;
    padding-left: 30px;
    color: var(--body-text-green);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control::placeholder {
    color: var(--body-text-green);
}

.bred_crumb .bred_text .search_bar form .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--dark-green);
}

.bred_crumb .bred_text .search_bar form .form-group .btn {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: var(--bg-green);
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 100px;
    text-align: center;
    line-height: 56px;
    font-size: 23px;
    color: var(--text-white);
}

.bred_crumb .bred_text .search_bar form .form-group .btn:hover {
    background-color: var(--dark-green);
}

/* ----About App Soluction Section--- */

.app_solution_section .row {
    align-items: center;
}

.app_solution_section .app_text .section_title {
    text-align: left;
    margin-bottom: 20px;
}

.app_solution_section .app_text .section_title h2 {
    letter-spacing: -1px;
}

.app_solution_section .app_text p {
    padding-right: 40px;
}

.app_solution_section .app_images {
    position: relative;
}

.app_solution_section .app_images ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.app_solution_section .app_images ul li {
    position: relative;
}

.app_solution_section .app_images ul li a {
    position: relative;
}

.app_solution_section .app_images ul li a .play_icon {
    background-color: rgba(255, 255, 255, 0.1);
    width: 96px;
    height: 96px;
    border-radius: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 96px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.app_solution_section .app_images ul li img {
    max-width: 100%;
}

.app_solution_section .app_images li:first-child {
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
}

.app_solution_section .app_images ul li:nth-child(2) {
    left: -5px;
    cursor: pointer;
}

.app_solution_section .app_images ul li:nth-child(3) {
    right: -5px;
}

.app_solution_section .app_images::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
    height: 570px;
    border-radius: 100%;
    background-color: var(--bg-white);
    z-index: -1;
}


/* ------Why Section CSS Start------ */

.why_we_section {
    display: flex;
    justify-content: center;
}

.why_we_section .why_inner {
    background-color: var(--bg-white);
    border-radius: 30px;
    width: 1370px;
    margin: 0 15px;
    box-shadow: 0 4px 30px #576769;
    padding: 85px 0;
}

.why_we_section .why_inner .section_title {
    margin-bottom: 50px;
}

.why_we_section .why_inner .why_box .icon {
    margin-bottom: 45px;
}

.why_we_section .why_inner .why_box .icon img {
    max-width: 100%;
}

.why_we_section .why_inner .why_box .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-green);
    margin-bottom: 10px;
}

.why_we_section .why_inner .why_box .text p {
    margin-bottom: 0;
}


/* About-Page-Sectino */

.about_page_sectino img {
    max-width: 100%;
}

/* -----------experts_team_sectio---------- */
.team_inner-section .section_title h2,
.team_inner-section .section_title p {
    color: #fff;
}

.team_inner-section .section_title {
    margin-bottom: 4em;
}

.experts_team_section .experts_box {
    position: relative;
    text-align: center;
    padding: 20px;
    border-radius: 12px;
    background-color: transparent;
    transition: .4s all;
}

.experts_team_section .experts_box img {
    margin-bottom: 30px;
    max-width: 100%;
}

.experts_team_section .experts_box .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    transition: .4s all;
}

.experts_team_section .experts_box .text span {
    color: #ffffff;
}

.experts_team_section .experts_box .social_media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.experts_team_section .experts_box .social_media a {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid #CCCCE2;
    border-radius: 50px;
    text-align: center;
    line-height: 31px;
    color: #898AAE;
    margin: 0 5px;
    transition: .4s all;
    font-size: 15px;
}

.experts_team_section .experts_box .social_media a:hover {
    border-color: var(--green);
    background-color: var(--bg-green);
    color: var(--text-white);
}

.experts_team_section .experts_box:hover {
    background-color: var(--bg-white);
    box-shadow: 0 4px 10px #576769;
}

.experts_team_section .experts_box:hover h3 {
    color: var(--body-text-green);
}

.experts_team_section .experts_box:hover span {
    color: var(--body-text-green);
}
.co_row .experts_box{
    margin-bottom: 40px;
}
.row.co_row .col-lg-6:nth-child(1) .experts_box:after {
    content: "";
    width: 53%;
    height: 3px;
    background: #fff;
    display: block;
    left: 50%;
    position: absolute;
    bottom: -40px;
}
.row.co_row .col-lg-6:nth-child(2) .experts_box:after {
    content: "";
    width: 52%;
    height: 3px;
    background: #fff;
    display: block;
    position: absolute;
    left: -2%;
    bottom: -40px;
}
.co_row .experts_box:before {
    content: "";
    width: 3px;
    height: 26px;
    background: #fff;
    position: absolute;
    bottom: -40px;
    left: 50%;
}
.team_row:before {
    content: "";
    background: #fff;
    width: 3px;
    height: 30px;
    top: 0px;
    left: 50%;
    position: absolute;
}

.team_row {
    padding-top: 30px;
    position: relative;
}
.team_row  .experts_box:before {
    content: "";
    width: 3px;
    height: 30px;
    background: #fff;
    top: -40px;
    position: absolute;
    left: 50%;
}
.team_row .experts_box{
    margin-top: 40px;
}
.team_row .experts_box:after {
    content: "";
    width: 110%;
    height: 3px;
    background: #fff;
    top: -40px;
    position: absolute;
    left: 50%;
}
.team_row .col-lg-3:last-child .experts_box:after{
    display: none;
}

/* ---------Query-Section-Css-Start-------- */
.query_section {
    display: flex;
    justify-content: center;
}

.query_inner {
    width: 1370px;
    margin: 0 15px;
    /*background-image: url(../images/query_bg.png);*/
    background: var(--green);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 30px;
    min-height: 420px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.query_inner .section_title h2,
.query_inner .section_title p {
    color: #fff;
}

.query_inner .section_title {
    margin-bottom: 40px;
}

.query_inner .white_btn {
    font-size: 25px;
    font-weight: 700;
}

.query_inner .white_btn:hover {
    border-color: #fff;
}

.about_trust_section {
    margin-bottom: 40px;
}


/* Blog List Css Start */
.blog_list_story {
    margin-bottom: 50px;
}

.blog_list_main {
    position: relative;
}

.blog_list_main>.container>.row {
    align-items: center;
}

.blog_list_main .blog_img {
    position: relative;
}

.blog_list_main .blog_img img {
    max-width: 100%;
    border-radius: 12px;
    width: 100%;
}

.blog_list_main .blog_img span {
    position: absolute;
    left: 15px;
    top: 15px;
    font-size: 14px;
    color: var(--text-white);
}

.blog_list_main .blog_text .section_title {
    text-align: left;
}

.blog_list_main .blog_text .choice_badge {
    font-size: 12px;
    background-color: #F8D979;
    display: inline-block;
    padding: 1px 10px;
    border-radius: 4px;
    color: var(--dark-green);
    font-weight: 700;
    margin-bottom: 10px;
}

.blog_list_main .blog_text a {
    color: var(--green);
    margin-top: 25px;
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.blog_list_main .blog_text a:hover {
    text-decoration: underline;
}

.blog_list_story.row_am {
    padding-top: 30px;
}

.blog_list_story .story_box {
    margin-bottom: 40px;
    text-align: left;
}

/* Pagination Css Start */
.pagination_block ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.pagination_block ul li:not(:first-child, :last-child) a {
    margin: 0 5px;
    transition: .4s all;
    display: block;
    width: 35px;
    height: 35px;
    background-color: transparent;
    border-radius: 50px;
    text-align: center;
    line-height: 35px;
    color: #fff;
}

.pagination_block ul li:first-child {
    margin-right: 20px;
}

.pagination_block ul li:last-child {
    margin-left: 20px;
}

.pagination_block ul li:not(:first-child, :last-child) a:hover,
.pagination_block ul li:not(:first-child, :last-child) a.active {
    background-color: var(--bg-green);
    color: var(--text-white);
}

.pagination_block ul li:first-child a:hover {
    color: var(--green);
}

.pagination_block ul li:last-child a:hover {
    color: var(--green);
}

/* Blog Detail Css Start */
.blog_detail_section {
    margin-bottom: 40px;
}

.blog_detail_bredcrumb.bred_crumb .bred_text {
    margin-top: -30px;
}

.blog_detail_section .blog_inner_pannel {
    background-color: var(--dark-green);
    border-radius: 30px;
    padding: 45px 60px;
    margin-top: -70px;
    position: relative;
    box-shadow: 0px 4px 30px #7fa2a6;
}

.blog_detail_section .blog_inner_pannel .review {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.blog_detail_section .blog_inner_pannel .review span {
    line-height: 1;
}

.blog_detail_section .blog_inner_pannel .review span:first-child {
    display: inline-block;
    font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .review span:last-child {
    /* color: var(--dark-green); */
    display: inline-block;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #ffffff;
}

.blog_detail_section .blog_inner_pannel .section_title {
    margin-bottom: 40px;
}

.blog_detail_section .blog_inner_pannel .section_title h2 {
    margin-bottom: 0;
}

.blog_detail_section .blog_inner_pannel img {
    max-width: 100%;
}

.blog_detail_section .blog_inner_pannel .main_img {
    margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .info h3 {
    font-weight: 700;
    color: var(--dark-green);
    margin-top: 30px;
    font-size: 25px;
    margin-bottom: 15px;
}

.blog_detail_section .blog_inner_pannel .info ul {
    padding-left: 50px;
    padding-top: 10px;
    margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .info ul li p {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
}

.blog_detail_section .blog_inner_pannel .info ul li p .icon {
    position: absolute;
    left: 0;
    color: var(--green);
}

.blog_detail_section .blog_inner_pannel .two_img {
    margin-bottom: 70px;
}

.blog_detail_section .blog_inner_pannel .quote_block {
    background-color: #acced2;
    border-radius: 12px;
    padding: 55px 35px 35px 60px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 35px;
}

.blog_detail_section .blog_inner_pannel .quote_block h2 {
    font-size: 25px;
    line-height: 37px;
}

.blog_detail_section .blog_inner_pannel .quote_block p {
    margin-bottom: 0;
    margin-top: 15px;
}

.blog_detail_section .blog_inner_pannel .quote_block .name {
    color: var(--green);
    font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon {
    position: absolute;
    top: -20px;
    width: 63px;
    height: 63px;
    background-color: var(--dark-green);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon img {
    width: 35px;
}

.blog_detail_section .blog_inner_pannel .blog_authore {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
    border-top: 1px solid #D6D3E3;
    border-bottom: 1px solid #D6D3E3;
    margin: 35px 0;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text {
    margin-left: 20px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-green);
    margin-bottom: 5px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text span {
    font-size: 14px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul {
    display: flex;
}

/* Social Media link list */
.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li a {
    display: block;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid #CCC9D7;
    border-radius: 50px;
    margin-right: 10px;
    transition: .4s all;
    color: #ffffff;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li:last-child a {
    margin-right: 0;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li a:hover {
    background-color: var(--bg-green);
    color: var(--text-white);
}

.blog_detail_section .blog_inner_pannel .blog_tags ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul .tags p {
    margin-bottom: 0;
    color: var(--dark-green);
    font-weight: 500;
    margin-right: 5px;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul li span {
    color: var(--green);
    font-weight: 500;
}


/* comment section Css Start */
.comment_section ul {
    margin-top: 30px;
}

.comment_section ul li {
    display: flex;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid #D6D3E3;
}

.comment_section ul li:last-child {
    padding-bottom: 0;
}

.comment_section ul li.replay_comment {
    margin-left: 110px;
}

.comment_section ul li .authore_info {
    display: flex;
    align-items: center;
    width: 260px;
}

.comment_section ul li .authore_info .avtar {
    width: 88px;
    margin-right: 20px;
}

.comment_section ul li .authore_info .text {
    width: calc(100% - 108px);
}

.comment_section ul li .authore_info .text h4 {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-green);
}

.comment_section ul li .authore_info .text span {
    font-size: 14px;
}

.comment_section ul li .comment {
    width: calc(100% - 310px);
    margin-left: 50px;
}

.comment_section ul li .comment p {
    margin-bottom: 0;
}



/* comment-form-section */

.comment_form_section form {
    margin-top: 30px;
}

.comment_form_section form .form-group .form-control {
    border-radius: 12px;
    height: 60px;
    box-shadow: 0 3px 6px #576769;
    border: transparent;
    margin-bottom: 30px;
    color: var(--body-text-green);
    padding: 15px;
}

.comment_form_section form .form-group .form-control::placeholder {
    color: var(--body-text-green);
}

.comment_form_section form .form-group textarea.form-control {
    height: 140px;
    padding-top: 15px;
    resize: none;
}


/* Sign Up Css Start */
.full_bg {
    /*background-image: url(../images/form_bg.png);*/
    background-size: cover;
    background: var(--green);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 50px;
}

.signup_section {
    position: relative;
}

.signup_section .back_btn {
    position: absolute;
    left: 0;
    top: 92px;
    color: #fff;
    font-weight: 500;
}

.signup_section .top_part {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 50px;
}

.signup_form {
    width: 570px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 12px;
    box-shadow: 0 4px 10px #00000054;
    background-color: var(--bg-white);
}

.signup_form form {
    padding: 0 60px;
}

.signup_form .section_title {
    padding: 0 15px;
}

.signup_form .section_title h2 {
    font-weight: 600;
}

.signup_form form .form-group {
    margin-bottom: 20px;
}

.signup_form form .form-group .form-control {
    height: 60px;
    padding: 5px 20px;
    color: var(--body-text-green);
    border: 2px solid #E1DBF4;
    border-radius: 12px;
    font-weight: 500;
}

.signup_form form .form-group .form-control:focus {
    box-shadow: none;
    border-color: var(--green);
    color: var(--green);
}

.signup_form form .form-group .puprple_btn {
    min-width: 240px;
    display: block;
    margin: 40px auto;
    margin-bottom: 30px;
    font-weight: 600;
}

.signup_form .or_block {
    display: block;
    text-align: center;
    border-bottom: 1px solid #E3E1ED;
}

.signup_form .or_block span {
    position: relative;
    top: 14px;
    padding: 0 5px;
    background-color: var(--bg-white);
    color: #8081AD;
}

.signup_form .or_option {
    text-align: center;
}

.signup_form .or_option>p {
    color: #8081AD;
    margin-top: 30px;
}

.signup_form .or_option .google_btn {
    min-width: 330px;
    border: 2px solid #E1DBF4;
    text-align: center;
    color: var(--body-text-green);
    padding: 16px;
    border-radius: 100px;
    transition: .4s all;
}

.signup_form .or_option .google_btn:hover {
    border-color: var(--light-green);
}

.signup_form .or_option .google_btn img {
    margin-right: 20px;
}

.signup_form .or_option p a {
    color: var(--green);
    text-decoration: underline;
    transition: .4s all;
}

.signup_form .or_option p a:hover {
    color: var(--dark-green);
}


/* Review List Section Css Start */

.review_list_section .review_box {
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 10px #576769;
    margin: 20px 0;
}

.review_list_section .review_box .rating ul {
    display: flex;
}

.review_list_section .review_box .rating ul li span {
    color: #FC9400;
    font-size: 18px;
}

.review_list_section .review_box h3 {
    font-size: 20px;
    color: var(--green);
    margin: 10px 0;
}

.review_list_section .review_box .reviewer {
    display: flex;
    align-items: center;
    margin-top: 35px;
}

.review_list_section .review_box .reviewer .avtar {
    width: 80px;
}

.review_list_section .review_box .reviewer .text {
    margin-left: 20px;
    width: calc(100% - 100px);
}

.review_list_section .review_box .reviewer .text h3 {
    font-weight: 600;
    color: var(--dark-green);
    margin-bottom: 0;
}

/* review-freeapp */

.review_freeapp {
    margin: 60px 0;
}

.review_freeapp .free_app_inner {
    /* background: url(../images/free_review_bg.png); */
    background-size: cover;
    background-position: 90%;
}

/* Contact Page Css Start */

.contact_page_section .contact_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: -150px;
}

.contact_page_section .contact_inner .section_title h2 {
    font-weight: 600;
}

.contact_page_section .contact_inner .contact_form {
    width: 570px;
    padding: 50px 60px;
    border-radius: 22px;
    background-color: #fff;
    box-shadow: 0 4px 10px #576769;
}

.contact_page_section .contact_inner .contact_form h2 {
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_form form {
    margin-top: 30px;
}

.contact_page_section .contact_inner .contact_form form .form-group {
    margin-bottom: 20px;
}

.contact_page_section .contact_inner .contact_form form .form-group .form-control {
    height: 60px;
    padding: 5px 20px;
    color: var(--body-text-green);
    border: 2px solid #E1DBF4;
    border-radius: 12px;
    font-weight: 500;
}

.contact_page_section .contact_inner .contact_form form .form-group .form-control:focus {
    box-shadow: none;
    border-color: var(--green);
    color: var(--green);
}

.contact_page_section .contact_inner .contact_form form .form-group textarea.form-control {
    height: 140px;
    padding-top: 15px;
}

.contact_page_section .contact_inner .contact_form form .term_check {
    display: flex;
    align-items: center;
}

.contact_page_section .contact_inner .contact_form form .term_check input {
    width: 17px;
    height: 17px;
    accent-color: var(--green);
}

.contact_page_section .contact_inner .contact_form form .term_check label {
    font-size: 13px;
    margin-bottom: 0;
    margin-left: 7px;
}

.contact_page_section .contact_inner .contact_form form .form-group button {
    width: 240px;
    margin: 0 auto;
    display: block;
    margin-top: 10px;
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info {
    width: 480px;
}

.contact_page_section .contact_inner .contact_info .section_title {
    text-align: left;
    margin-top: 15px;
}

.contact_page_section .contact_inner .contact_info .section_title p a {
    color: var(--green);
    text-decoration: underline;
}

.contact_page_section .contact_inner .contact_info .btn {
    width: 180px;
    margin: 10px 0 50px 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li:last-child {
    margin-bottom: 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .img {
    width: 65px;
    margin-right: 20px;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text {
    width: calc(100% - 85px);
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text span {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-green);
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text p,
.contact_page_section .contact_inner .contact_info .contact_info_list li .text a {
    margin: 0;
}

.contact_page_section .contact_inner .contact_info .contact_info_list li .text a:hover {
    color: var(--dark-green);
}

.map_section {
    margin: 50px 0;
}

.sendsms {
    display: none;
}

.sticky_buttons {
    display: none;
}

.MainP {
    min-height: 200px;
}

.MainH {
    min-height: 71px;
}