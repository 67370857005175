@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,500&display=swap");

.mar-20 {
  margin-top: 20px;
}

.mar-30 {
  margin-top: 30px;
}

.mar-40 {
  margin-top: 40px;
}

.mar-50 {
  margin-top: 50px;
}

.mar-100 {
  margin-top: 100px;
}

.mar-150 {
  margin-top: 200px;
}

.padd {
  padding-left: 15px;
  padding-right: 15px;
}

.main-bg {
  background-image: url(/public/images/main-bg.png);
  position: relative;
  min-height: auto;
  background-size: cover;
  background-position: center center;
  padding: 0px 0px 0px 0px;
  background-attachment: fixed;
  padding-bottom: 50px;
  min-height: 100vh;
}

.TopBox {
  background: rgba(77, 110, 114, 0.8);
  padding: 0px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
}

.TopLogPadding {
  padding: 5px 0px 0px 0px;
}

.TopBg {
  background: #799396;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border-radius: 20px 20px 0px 0px;
  margin-top: 50px;
}

.TopBg h2 {
  font-size: 26px;
  color: #fff;
  font-weight: 500;
  margin: 0px;
  padding: 5px 0px 5px 10px;
  text-align: center;
}

.BoxBg {
  background: rgba(255, 255, 255, 0.8);
  padding: 2px 30px 30px 30px;
}

.BoxBg p {
  font-size: 26px;
  color: #fff;
  font-weight: 500;
  margin: 0px;
  padding: 0px 0px 0px 10px;
  text-align: center;
}

.box-left {
  display: flex;
  column-gap: 18px;
  margin: 30px 0px 0px 0px;
}

.box-left h2 {
  color: #31595e;
  font-family: "Roboto";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.box-left span {
  color: #1d1d1d;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 5px;
}

.box-left .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.contabtCol {
  padding: 10px 20px 0px 20px;
  border: 1px #045d5d solid;
  border-radius: 15px;
  margin: 30px 0px 0px 0px;
}

.contabtCol h2 {
  color: #000000;
  font-family: "Roboto";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  text-align: left;
}

.contabtCol h3 {
  color: #055252;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  text-align: left;
  /* word-break: break-all; */
}

.contabtCol p {
  color: #767373;
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px 0px 10px 0px;
  padding: 0px;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .BoxBg {
    background: rgba(255, 255, 255, 0.8);
    padding: 2px 15px 30px 15px;
  }
  .TopBg h2 {
    font-size: 16px;
  }
  .box-left h2 {
    font-size: 20px;
  }

  .box-left .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .contabtCol h2 {
    color: #31595e;
    font-size: 16px;
  }

  .contabtCol p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .contabtCol h3 {
    color: #055252;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    text-align: left;
    white-space: normal;
    word-break: break-all;
  }

  .box-left span {
    color: #1d1d1d;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 5px;
  }
}
